import React from 'react'
import { Buffer } from "buffer";

// @mui material components
import { Card, Grid, Icon } from "@mui/material";



// @progress telerik components
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { process } from "@progress/kendo-data-query";
import { Grid as GridTelerik, GridToolbar, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Input as InputTelerik } from "@progress/kendo-react-inputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Switch } from "@progress/kendo-react-inputs";

// Material Dashboard 2 PRO React examples
import MDBox from "components/MDBox";


//Hooks
import { useLocalityStateStore } from 'hooks';
import { useResidentStore } from 'hooks';
import { useUserStore } from 'hooks';
import { LocalityContactPhones } from "./LocalityContactPhones";
import { LocalityResidents } from './LocalityResidents';
import { BlackList } from './BlackList';
import { PreAuthorized } from './PreAuthorized';
import { Vehicles } from './Vehicles';

import excelImage from "assets/images/safe-entry/excel.png";
import { clearBlackListDataStatus } from 'store/resident';

export const SearchLocalityData = (lables) => {

    //#region Consumo Api
    const { loadLocalityData, clearPreauthorizedStatus, loadLocalityMz, apiLocalityMzData, newMz, errorMessagePreauthorized, errorMessageResidents,
        apiSearchLocalityData, errorMessage, updateLocalityInformation, loadLocalityType, loadSearchLocalityData,
        newLocality, localityNotificationList, loadResidentLocalityData, updateLocalityNotification,
        apiLocalityNotificationData, clearPreauthorized, preAuthorizeData, statusLocalityLoad, clearResidentLocality, clearViewData } = useLocalityStateStore();

    const { contactLocalityList, clearBlackList, clearVehicleDataStatus, blackListResident, errorMessageResident,
        clearLocalityContactPhone, vehicleResident, clearVehicles } = useResidentStore();

    const { loadCompanyData, apiCompanyData, errorUserMessage, statusUserLoad } = useUserStore();

    //#endregion Consumo Api

    //#region Filtros 
    const darkModeStyle = {
        color: "rgb(0, 0, 0)",
    };

    const position = {
        bottomRight: {
            bottom: 0,
            right: 0,
            alignItems: "flex-end",
        },
        topRight: {
            top: 5,
            left: "95%",
            alignItems: "flex-end",
        },
        bottomLeft: {
            bottom: 0,
            left: "96%",
            alignItems: "flex-start",
        },
    }

    //#region Notificaciones
    const [notification, setNotification] = React.useState(false);
    const [errorNotification, setErrorNotification] = React.useState();
    const viewNotification = (state) => {
        setNotification(state)
    }
    const [notificationSuccess, setNotificationSuccess] = React.useState();
    const viewNotificationSuccess = (state) => {
        setNotificationSuccess(state)
    }

    const [content, setContent] = React.useState();
    //#endregion Notificaciones


    const userData = localStorage.getItem("user");

    // Obtener los datos del localStogare de la sesion
    const base64dataEnterprises = localStorage.getItem("key1");
    const Enterprises = Buffer.from(base64dataEnterprises, "base64").toString("ascii");
    const dataEnterprises = JSON.parse(Enterprises);

    const base64dataCompanies = localStorage.getItem("key2");
    const Companies = Buffer.from(base64dataCompanies, "base64").toString("ascii");
    const dataCompanies = JSON.parse(Companies);

    const localityCatalog = localStorage.getItem("locality_catalog");
    const dataLocalityCatalog = JSON.parse(localityCatalog);

    const data_labels = localStorage.getItem("key3")
    const labels = Buffer.from(data_labels, "base64").toString("ascii");
    //console.log(labels)
    const dataLabels = JSON.parse(labels);
    //console.log(dataLabels)
    //const dataLabels = JSON.parse(labels);



    const [enterprise, setEnterprise] = React.useState(dataEnterprises[0]);
    const handleEntChange = (event) => {
        setEnterprise(event.target.value !== null ? event.target.value : "");
        setCompany([])
        setFilter("")
    };

    const [company, setCompany] = React.useState(dataCompanies[0]);
    const [filter, setFilter] = React.useState(dataCompanies[0].company_name);
    const [adaptiveFilter, setAdaptiveFilter] = React.useState("");
    const [labelCompany, setLabelCompany] = React.useState(
        company !== null && (company.length > 0 || company.length === undefined)
          ? dataLabels[company.company_uuid].map(({ lac_origin, lac_reference }) => ({ lac_origin, lac_reference }))
          : []
      );


    const handleConChange = (event) => {
        setCompany(event.target.value !== null ? event.target.value : []);
        setFilter(event.value.company_name !== null ? event.target.company_name : "");
        //console.log(event.value)
        setAdaptiveFilter("");
        //console.log(dataLabels[event.value.company_uuid].map(({ lac_origin, lac_reference }) => ({ lac_origin, lac_reference })));
        if (event.target.value === null){
            setLabelCompany([].map(({ lac_origin, lac_reference }) => ({ lac_origin, lac_reference })))
            setLocalities([])
        }if (dataLabels[event.value.company_uuid] === undefined || dataLabels[event.value.company_uuid].length === 0) {
            setLabelCompany([].map(({ lac_origin, lac_reference }) => ({ lac_origin, lac_reference })))
            setLocalities([])
            viewNotification(true)
            setErrorNotification("Error: Etiquetas No Asignadas. Comuníquese con Soporte")
            setTimeout(() => {
                viewNotification(false)
            }, 4000);
        }  
        
        else{
            setLabelCompany(dataLabels[event.value.company_uuid].map(({ lac_origin, lac_reference }) => ({ lac_origin, lac_reference })))
        }

        //setLabelCompanyGrid(dataLabels[event.value.company_uuid].map(({ lac_origin, lac_reference }) => ({ lac_origin, lac_reference })))
        setLocalities([])
    };

    const [companiesData, setCompaniesData] = React.useState([]);
    const filterData = (f) => filterBy(companiesData.slice(), f);
    const filterChange = (event) => {
        //console.log(event)
        const request_companies = {
            user_uuid: userData,
            enterprise_uuid: enterprise.enterprise_uuid,
        };
        if (event.filter.value === '') {

            loadCompanyData(request_companies)
        }
        if (event.target.mobileMode) {
            setAdaptiveFilter(event.filter.value);
        } else {
            setFilter(event.filter.value);
            //loadCompanyData(request_companies)
        }
        setCompaniesData(filterData(event.filter))

    };

    const handleClose = () => {
        setAdaptiveFilter("");
        //setLabelCompany(dataLabels[company.company_uuid].map(({ lac_origin, lac_reference }) => ({ lac_origin, lac_reference })))
    };

    const handleClickCompany = (event) => {
        const request_companies = {
            user_uuid: userData,
            enterprise_uuid: enterprise.enterprise_uuid,
        };
        loadCompanyData(request_companies)

    }

    const [type, setType] = React.useState(null);
    const handleTypeChange = (event) => {
        setType(event.target.value);

    };



    //#region DataGrid
    const [loading, setLoading] = React.useState(false);
    const initialDataState = {
        sort: [
            {
                field: "locality.id_localidad",
                dir: "desc",
            },
        ],
        take: 5,
        skip: 0,
    };

    const [dataState, setDataState] = React.useState(initialDataState);
    //#endregion

    //#region Api
    const [openForm, setOpenForm] = React.useState(false)
    const [localities, setLocalities] = React.useState([]);
    const [dataStateResident, setDataStateResident] = React.useState([]);
    const [dataContactLocality, setDataContactLocality] = React.useState([]);
    const [editItem, setEditItem] = React.useState();
    const [openNewLocalityForm, setOpenNewLocalityForm] = React.useState(false);
    const [localityMzGroupID, setLocalityMzGroupID] = React.useState();
    const [localitiesMz, setLocalitiesMz] = React.useState([]);
    const [mzType, setMzType] = React.useState();
    const [localityNotification, setLocalityNotification] = React.useState();
    const [localityNotificationData, setLocalityNotificationData] = React.useState([]);
    const [openFormNotification, setOpenFormNotification] = React.useState(false)
    const [manzana, setManzana] = React.useState();
    const [villa, setVilla] = React.useState();
    const [building, setBuilding] = React.useState();
    const [floor, setFloor] = React.useState();
    const [department, setDepartment] = React.useState();
    const [actionResidentWindow, setActionResidentWindow] = React.useState(false);
    //#endregion Api

    //#region formState Data Localidad
    const [formState, setFormState] = React.useState({
        mz: '',
        villa: '',
        floor: '',
        department: '',
        building: '',
    });

    const handleChange = (event) => {
        setFormState({ ...formState, [event.target.name]: event.target.value });
    };
    //#endregion


    const handleSubmit = (event) => {
        event.preventDefault();
        loadLocalityData(enterprise.enterprise_uuid, company.company_uuid);
        //setLabelCompany(dataLabels[company.company_uuid].map(({ lac_origin, lac_reference }) => ({ lac_origin, lac_reference })))
        setLoading(true);
        const request_search_locality = {
            uuid_company: company.company_uuid,
            uuid_locality_catalog: type === null ? "" : type.lca_uuid,
            manzana: (formState.mz).toUpperCase(),
            villa: (formState.villa).toUpperCase(),
            piso: (formState.floor).toUpperCase(),
            departamento: (formState.department).toUpperCase(),
            edificio: (formState.building).toUpperCase(),
        }
        loadSearchLocalityData(request_search_locality)

    };

    //#region Manzana
    const handleLocalitiesMzChange = (event) => {
        setLocalityMzGroupID(event.target.value)
    }

    const [openAddNewMz, setOpenAddNewMz] = React.useState(false);
    const handleNewMz = () => {
        setOpenAddNewMz(true)
    };
    const handleNewMzType = (event) => {
        setMzType(event.value.lca_id)
    }

    const newMzHandleSubmit = (dataItem) => {
        const userData = localStorage.getItem("user");
        const request_new_mz={
            manzana: (dataItem.manzana).toUpperCase(),
            description: (dataItem.description).toUpperCase(),
            catalog_id: mzType,
            creator_id: userData,
            enterprise_uuid: enterprise.enterprise_uuid,
            company_uuid: company.company_uuid
        }
        newMz(request_new_mz);
        loadLocalityMz(company.company_uuid, enterprise.enterprise_uuid)
        setLoading(true)
        
    }
    //#endregion Manzana

    //#region Nueva Localidad
    const handleOpenNewLocalityForm = (event) => {
        setOpenNewLocalityForm(true)
        loadLocalityMz(company.company_uuid, enterprise.enterprise_uuid)
        //setLocalities(event.target.value)
    }


    const NewLocalityHandleSubmit = (dataItem) => {
        setLoading(true)
        const request_new_locality = {
            villa: ((dataItem.villa).trim()).toUpperCase(),
            floor: ((dataItem.floor).trim()).toUpperCase(),
            department: ((dataItem.department).trim()).toUpperCase(),
            //department: dataItem.department === "" ? null : dataItem.department,
            description: "",
            address: "",
            building: ((dataItem.building).trim()).toUpperCase(),
            longitud: "",
            latitude: "",
            locality_group_id: localityMzGroupID.id,
            locality_notification_type: 2,
            creator_id: userData,
            enterprise_uuid: enterprise.enterprise_uuid,
            company_uuid: company.company_uuid,
        }
        //console.log(request_new_locality)
        newLocality(request_new_locality);
        loadLocalityData(enterprise.enterprise_uuid, company.company_uuid);
        loadLocalityType()
        loadSearchLocalityData({
            uuid_company: company.company_uuid,
            uuid_locality_catalog: type === null ? "" : type.lca_uuid,
            manzana: (formState.mz).toUpperCase(),
            villa: (formState.villa).toUpperCase(),
            piso: (formState.floor).toUpperCase(),
            departamento: (formState.department).toUpperCase(),
            edificio: (formState.building).toUpperCase(),
        })
        //setOpenNewLocalityForm(false)
    }
    //#endregion Nueva Localidad

    //#region Cancel Edit
    const handleCancelEdit = () => {
        setOpenForm(false);
        //setOpenAddResidenForm(false)
        setOpenNewLocalityForm(false)
        setActionResidentWindow(false)
        setOpenFormNotification(false)
        setSelectedAction(0)
        clearResidentLocality();
        clearPreauthorizedStatus();
        clearPreauthorized();
        clearVehicles();
        clearBlackList();
        clearBlackListDataStatus();
    };

    const handleCancelNewMz = () => {
        setOpenAddNewMz(false)
    }
    //#endregion Cancel edit

    //#region Estado Localidad
    const StateCell = (props) => {
        return (
            <td style={{
                textAlign: "center",
            }}>

                {/* {props.dataItem.locality.loc_is_deleted ? "INACTIVO" : "ACTIVO"}
             */}
                <Switch disabled={true} defaultChecked={props.dataItem.locality.loc_is_deleted} />
                <h6>{props.dataItem.locality.loc_is_deleted ? "INACTIVO" : "ACTIVO"}</h6>

            </td>
        )
    }
    //#endregion Estado Localidad

    //#region Acciones Localidad
    const cellWidthAction = ({ dataItem }) => {
        return (
            <td
                style={{
                    textAlign: "center",
                }}
            >
                <Button
                    title="Modificar Localidad"
                    onClick={() => enterAction(dataItem)}
                    fillMode="flat"
                >
                    <Icon fontSize="small" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
                        person
                    </Icon>
                </Button>

                <Button
                    title="Modificar Notificación"
                    onClick={() => enterNotification(dataItem)}
                    fillMode="flat">
                    <Icon fontSize="small" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
                        home
                    </Icon>
                </Button>
            </td>
        );
    }

    const [idLocality, setIdLocality] = React.useState(0)
    const [uuidLocality, setUuidLocality] = React.useState()


    const enterAction = (item) => {
        setEditItem(item);
        setActionResidentWindow(true)
        setManzana(item.locality_group.lgr_mz)
        setVilla(item.locality.loc_villa)
        setBuilding(item.locality.loc_building)
        setFloor(item.locality.loc_floor)
        setDepartment(item.locality.loc_department)
        setIdLocality(parseInt(item.locality.id_localidad))
        setUuidLocality(item.locality.uuid_localidad)
        loadResidentLocalityData(item.locality.id_localidad)
        // Eliminar el redux de telefono de contacto
        clearResidentLocality();
        //clearPreauthorizedStatus();
        clearPreauthorized();
        clearLocalityContactPhone();
        clearBlackList();
        clearVehicles();
        clearVehicleDataStatus();
    }
    //#endregion Acciones Localidad

    //#region Notificaciones
    const cellWidthModifyNotification = ({ dataItem }) => {
        return (
            <td
                style={{
                    textAlign: "center",
                }}
            >
                <Button
                    title="Modificar Notificación"
                    onClick={() => enterNotification(dataItem)}
                    fillMode="flat">
                    <Icon fontSize="small" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
                        home
                    </Icon>
                </Button>
            </td>
        );
    };

    //const [value, setValue] = React.useState({})

    const enterNotification = (item) => {
        setEditItem(item);
        localityNotificationList("98fc8ef6-d1e0-48b8-98a5-7c874cb59742")
        setLocalityNotification((item.Notification.notification_type_id))
        setOpenFormNotification(true)
    }

    const handleLocalityNotificationChange = (event) => {
        setLocalityNotification(event.target.value.nti_id)
    }

    const LocalityNotificationHandleSubmit = (dataItem) => {
        const userData = localStorage.getItem("user");
        setLoading(true)
        updateLocalityNotification({
            locality_notification_uuid: editItem.Notification.notification_uuid,
            locality_notification_type: localityNotification,
            modifier_id: userData,
        });
        loadLocalityData(enterprise.enterprise_uuid, company.company_uuid);
        loadLocalityType()
        loadSearchLocalityData({
            uuid_company: company.company_uuid,
            uuid_locality_catalog: type === null ? "" : type.lca_uuid,
            manzana: (formState.mz).toUpperCase(),
            villa: (formState.villa).toUpperCase(),
            piso: (formState.floor).toUpperCase(),
            departamento: (formState.department).toUpperCase(),
            edificio: (formState.building).toUpperCase(),
        })
        setLoading(false)
        //setOpenFormNotification(false)
        //viewNotificationSuccess(true)
        setTimeout(() => {
            viewNotificationSuccess(false);
        }, 4000);
        //setLoading(false)
    }

    //#endregion Notificacion

    //#region Actualizacion Localidad
    const LocalityHandleSubmit = (dataItem) => {
        const userData = localStorage.getItem("user");
        setLoading(true)

        const request_update_locality_inf = {
            uuid: editItem.locality.uuid_localidad,
            villa: ((dataItem.villa).trim()).toUpperCase() === "N/D" ? "" : ((dataItem.villa).trim()).toUpperCase(),
            floor: ((dataItem.floor).trim()).toUpperCase() === "N/D" ? "" : ((dataItem.floor).trim()).toUpperCase(),
            department: ((dataItem.department).trim()).toUpperCase() === "N/D" ? "" : ((dataItem.department).trim()).toUpperCase(),
            description: "",
            address: "",
            building: ((dataItem.building).trim()).toUpperCase() === "N/D" ? "" : ((dataItem.building).trim()).toUpperCase(),
            longitud: "",
            latitude: "",
            locality_group_id: editItem.locality_group.id_group,
            modifier_id: userData,
            enterprise_uuid: enterprise.enterprise_uuid,
            company_uuid: company.company_uuid
        }
        //console.log(request_update_locality_inf)
        updateLocalityInformation(request_update_locality_inf);
        loadLocalityData(enterprise.enterprise_uuid, company.company_uuid);
        loadLocalityType()
        loadSearchLocalityData({
            uuid_company: company.company_uuid,
            uuid_locality_catalog: type === null ? "" : type.lca_uuid,
            manzana: (formState.mz).toUpperCase(),
            villa: (formState.villa).toUpperCase(),
            piso: (formState.floor).toUpperCase(),
            departamento: (formState.department).toUpperCase(),
            edificio: (formState.building).toUpperCase(),
        })
        setLoading(false)
        setOpenForm(false)
        //setOpenFormNotification(false)
        //setLoading(false)
    }
    //#endregion Actualizacion Localidad

    //#region Seleccionar Pestana Acciones Residnetes
    const [selectedAction, setSelectedAction] = React.useState(0);
    const handleSelectAction = (e) => {
        setSelectedAction(e.selected);
        if (e.selected === 0) {
            // Cargar los datos de los residentes
            loadResidentLocalityData(idLocality);
            clearResidentLocality();
        }
        if (e.selected === 1) {
            // Cargar los datos de los telefono de contacto
            contactLocalityList(uuidLocality)
            clearLocalityContactPhone();
        }

        if (e.selected === 2) {
            // Cargar los datos de lista negra
            blackListResident({
                bli_gbl_locality_id: idLocality,
                bli_gbl_company_uuid: company.company_uuid
            })
            // Eliminar el redux de telefono de contacto
            //clearLocalityContactPhone();
            clearBlackList();
        }
        if (e.selected === 3) {
            preAuthorizeData({
                pre_locality_id: idLocality
            })
            //clearLocalityContactPhone();
            clearPreauthorized();
        }
        if (e.selected === 4) {
            //console.log(idLocality)
            vehicleResident({
                locality_id: idLocality
            })
            loadResidentLocalityData(idLocality);
            clearVehicleDataStatus();

        }
    };
    //#endregion Pestanas de acciones residentes

    //Parametrizacion de las etiquetas del front de acuerdo a la compañia seleccionada
    const labelComponent = labelCompany.map((etiqueta, index) => (
        <Grid key={index} item xs={12} md={3} lg={labelCompany.length <= 4 ? 2.7 : 2.2}>
            <h6 style={darkModeStyle}>{etiqueta.lac_reference}</h6>
            <InputTelerik
                style={{
                    width: "100%",
                }}
                name={(etiqueta.lac_origin).toLowerCase()} // Utiliza el valor de la etiqueta como nombre
                maxLength={32}
                onChange={handleChange}
            />
        </Grid>
    ));

    const labelGrid = labelCompany.map((etiqueta, index) => (
        <GridColumn
            key={index}
            field={(etiqueta.lac_origin) === "MZ" ? 'locality_group.lgr_mz' : `locality.loc_${((etiqueta.lac_origin).toLowerCase())}`} // Usa el valor de la etiqueta como parte del campo
            title={etiqueta.lac_reference}
            maxLength={32}
            width="250px"
        />
    ));

    const labelField = labelCompany.map((etiqueta, index) => (
        <div key={index} className="mb-3">
            <Field
                name={(etiqueta.lac_origin) === "MZ" ? 'manzana' : ((etiqueta.lac_origin).toLowerCase())}
                label={etiqueta.lac_reference}
                component={InputTelerik}
                maxLength={etiqueta.maxLength}  // Asegúrate de tener un atributo maxLength definido en tu array
            />
        </div>
    ));


    const labelFieldset = labelCompany.filter(etiqueta => etiqueta.lac_origin !== "MZ").map((etiqueta, index) => (
        <div key={index} className="mb-3">
            <Field
                name={((etiqueta.lac_origin).toLowerCase())}
                component={InputTelerik}
                label={etiqueta.lac_reference} // Capitalizar la primera letra
                maxLength={((etiqueta.lac_origin).toLowerCase()) === "building" ? 32 : 32} // Establecer maxLength según el campo
            />
        </div>
    ));

    const labelMzFieldset = labelCompany.filter(etiqueta => etiqueta.lac_origin === "MZ").map((etiqueta, index) => (
        <div key={index} className="mb-3">
            <h6 style={darkModeStyle}>{etiqueta.lac_reference}</h6>
            <ComboBox
                id="cbxManzana"
                style={darkModeStyle}
                data={localitiesMz} //Corregir
                textField={"field"}//Corregir
                onChange={handleLocalitiesMzChange}
                placeholder={`SELECCIONE ${etiqueta.lac_reference}`}
                //value={localitiesMz} //Corregir
                fillMode="outline"
                required
                validationMessage="Obligatorio"
            />

            <button
                title={`Nueva ${etiqueta.lac_reference}`}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                onClick={handleNewMz}
                style={{ marginTop: '20px' }}
            >
                <Icon fontSize="medium" sx={{ color: "rgb(235, 234, 232)" }}>
                    add_circle
                </Icon>
                {`AÑADIR ${etiqueta.lac_reference}`}
            </button>
        </div>
    ));

    const labelNewMzField = labelCompany.filter(etiqueta => etiqueta.lac_origin === "MZ").map((etiqueta, index) => (
        <div key={index} className="mb-3">
            <Field
                name={'manzana'}
                component={InputTelerik}
                label={(etiqueta.lac_reference).charAt(0).toUpperCase() + (etiqueta.lac_reference).slice(1).toLowerCase()}
                maxLength={32}
                required
                validationMessage={"Obligatorio"}
            />
        </div>
    ))


    React.useEffect(() => {
        if (apiLocalityMzData?.data !== undefined) {
            setLocalitiesMz(apiLocalityMzData?.data);
            setLoading(false)
        } else {
            setLocalitiesMz([])
        }
        setOpenForm(false);
        setOpenAddNewMz(false)
    }, [apiLocalityMzData])

    React.useEffect(() => {
        if (apiSearchLocalityData.data !== undefined) {
            setLocalities(apiSearchLocalityData?.data);
            setLoading(false)
        } else {
            setLocalities([]);
        }

    }, [apiSearchLocalityData])

    React.useEffect(() => {
        //console.log(apiLocalityNotificationData?.data)
        if (apiLocalityNotificationData?.data !== undefined) {
            setLocalityNotificationData(apiLocalityNotificationData?.data);
            setLoading(false)
        } else {
            setLocalityNotificationData([])
        }
        //setOpenForm(false);
        //setOpenAddResidenForm(false)
        //setOpenNewLocalityForm(false)
        //setActionResidentWindow(false)
        setOpenAddNewMz(false)
    }, [apiLocalityNotificationData])

    React.useEffect(() => {
        //console.log(statusUserLoad)
        if (statusUserLoad === '1') {
            setCompaniesData(apiCompanyData?.data)
        } else {
            setCompaniesData([])
        }
    }, [apiCompanyData, statusUserLoad])

    React.useEffect(() => {
        if (errorMessage !== undefined) {
            console.log(errorMessage)
            setLocalities([])
            //setResidentData([]);
            setDataStateResident([]);
            setDataContactLocality([]);
            viewNotification(true)
            setLoading(false)
            setErrorNotification(errorMessage)
            setTimeout(() => {
                viewNotification(false)
            }, 4000);
        }
    }, [errorMessage])

    React.useEffect(() => {
        if (errorMessageResident !== undefined) {
            setDataStateResident([]);
            setDataContactLocality([]);
            viewNotification(true)
            setLoading(false)
            setErrorNotification(errorMessageResident)
            setTimeout(() => {
                viewNotification(false)
            }, 4000);
        }
    }, [errorMessageResident])

    React.useEffect(() => {
        if (statusLocalityLoad === '3') {
            setOpenNewLocalityForm(false)
            setContent("Registro Guardado Exitosamente")
            viewNotificationSuccess(true)
            setTimeout(() => {
                viewNotificationSuccess(false);
            }, 4000);
            loadSearchLocalityData({
                uuid_company: company.company_uuid,
                uuid_locality_catalog: type === null ? "" : type.lca_uuid,
                manzana: (formState.mz).toUpperCase(),
                villa: (formState.villa).toUpperCase(),
                piso: (formState.floor).toUpperCase(),
                departamento: (formState.department).toUpperCase(),
                edificio: (formState.building).toUpperCase(),
            })
        }
        
    }, [statusLocalityLoad])

    React.useEffect(() => {
        //console.log(statusLocalityLoad)
        if (statusLocalityLoad === '2') {
            setOpenNewLocalityForm(false)
            setOpenFormNotification(false)
            setContent("Registro Actualizado Exitosamente")
            viewNotificationSuccess(true)
            setTimeout(() => {
                viewNotificationSuccess(false);
            }, 4000);
            // loadSearchLocalityData({
            //     uuid_company: company.company_uuid,
            //     uuid_locality_catalog: type === null ? "" : type.lca_uuid,
            //     manzana: (formState.mz).toUpperCase(),
            //     villa: (formState.villa).toUpperCase(),
            //     piso: (formState.floor).toUpperCase(),
            //     departamento: (formState.department).toUpperCase(),
            //     edificio: (formState.building).toUpperCase(),
            // })
        }
    }, [statusLocalityLoad])

    //#region ExportarExcel
    let _export;
    const exportExcel = () => {
        _export.save();
    };
    const handleExport = () => {
        setLoading(false);
    };
    //#endregion ExportExcel

    return (
        <>
            <form className="k-form" onSubmit={handleSubmit}>
                <MDBox py={2}>
                    <Card>
                        <Grid container spacing={3} p={2}>
                            <Grid item xs={12} md={4} lg={4}>
                                <MDBox>
                                    <h6 style={darkModeStyle}>EMPRESA</h6>
                                    <ComboBox
                                        id="cbxEnterprise"
                                        style={darkModeStyle}
                                        data={dataEnterprises}
                                        textField="enterprise_name"
                                        onChange={handleEntChange}
                                        placeholder="Seleccione Empresa"
                                        value={enterprise}
                                        fillMode="outline"
                                        validationMessage="Obligatorio"
                                        required
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <MDBox onClick={handleClickCompany}>
                                    <h6 style={darkModeStyle}>COMPAÑIA</h6>
                                    <ComboBox
                                        id="cbxCompany"
                                        style={darkModeStyle}
                                        data={companiesData}
                                        textField="company_name"
                                        onChange={handleConChange}
                                        //onClick={handleClickCompany}
                                        placeholder="Seleccione Compañía"
                                        value={company}
                                        fillMode="outline"
                                        required
                                        validationMessage="Obligatorio"
                                        filterable={true}
                                        filter={filter}
                                        adaptiveFilter={adaptiveFilter}
                                        onFilterChange={filterChange}
                                        onClose={handleClose}
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <MDBox>
                                    <h6 style={darkModeStyle}>TIPO</h6>
                                    <ComboBox
                                        id="cbxType"
                                        style={darkModeStyle}
                                        data={dataLocalityCatalog}
                                        textField="lca_description"
                                        onChange={handleTypeChange}
                                        placeholder="Seleccione Tipo"
                                        //value={type}
                                        fillMode="outline"
                                    //required
                                    />
                                </MDBox>
                            </Grid>
                            {labelComponent}

                            <Grid item xs={12} md={1} lg={1}>
                                <MDBox>
                                    <h6 style={darkModeStyle}>BUSCAR</h6>
                                    <Button type="submit" title="Buscar" fillMode="flat">
                                        <Icon fontSize="medium" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
                                            search
                                        </Icon>
                                    </Button>
                                </MDBox>
                            </Grid>
                        </Grid>

                    </Card>
                </MDBox>
            </form>
            <MDBox py={1}>
                <ExcelExport
                    data={localities}
                    fileName="ListadoDeLocalidades.xlsx"
                    filterable={true}
                    ref={(exporter) => {
                        _export = exporter;
                    }}
                    onExportComplete={handleExport}>
                    <GridTelerik
                        pageable={{
                            pageSizes: [5, 7, 10, 20, 30, 50, 100],
                        }}
                        resizable
                        reorderable={true}
                        filterable={true}
                        sortable={true}
                        data={process(localities, dataState)}
                        {...dataState}
                        onDataStateChange={(e) => {
                            setDataState(e.dataState);
                        }}
                        style={{
                            height: "53vh",
                            //width: "500",
                        }}
                    >

                        <GridToolbar>
                            <button
                                title="Nueva Localidad"
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                onClick={handleOpenNewLocalityForm}
                            >
                                <Icon fontSize="medium" sx={{ color: "rgb(235, 234, 232)" }}>
                                    add_circle
                                </Icon>
                                Nueva Localidad
                            </button>



                            <button
                                title="Exportar Excel"
                                className="k-button k-button-md k-rounded-md k-button-solid "
                                onClick={exportExcel}
                            >
                                <img src={excelImage} width="auto" height="25px" alt="" />
                                Excel
                                {/* <span className="k-icon k-i-file-excel k-icon-64"></span>Excel */}
                            </button>

                        </GridToolbar>

                        <GridColumn
                            field="locality_catalog.catalog_desc"
                            title="TIPO"
                            width="280px"
                        />

                        {labelGrid}

                        <GridColumn
                            field="locality.loc_is_deleted"
                            title="ESTADO"
                            filter="boolean"
                            width="250px"
                            cell={StateCell}
                        />

                        <GridColumn
                            title="OPCIONES"
                            width="250px"
                            filterable={false}
                            cell={cellWidthAction}
                        />

                        {/* <GridColumn
                            title="NOTIFICACIONES"
                            cell={cellWidthModifyNotification}
                            width="200px"
                            filterable={false}
                        /> */}

                    </GridTelerik>
                </ExcelExport>


            </MDBox>

            {loading && (
                <Dialog>
                    <Loader
                        size={"large"}
                        type={"converging-spinner"}
                    />
                </Dialog>
            )}

            {openNewLocalityForm && (
                <Dialog onClose={handleCancelEdit} title='Ingrese Datos de la Localidad'>
                    <Form
                        onSubmit={NewLocalityHandleSubmit}
                        initialValues={{
                            manzana: "",
                            villa: "",
                            building: "",
                            floor: "",
                            department: "",
                        }}
                        render={(formRenderProps) => (
                            <FormElement
                                style={{
                                    maxWidth: 650,
                                }}
                            >
                                {/* <div className="mb-3">
                                    <h6 style={darkModeStyle}>MANZANA</h6>
                                    <ComboBox
                                        id="cbxManzana"
                                        style={darkModeStyle}
                                        data={localitiesMz} //Corregir
                                        textField={"field"}//Corregir
                                        onChange={handleLocalitiesMzChange}
                                        placeholder="Seleccione Manzana"
                                        //value={localitiesMz} //Corregir
                                        fillMode="outline"
                                        required
                                        validationMessage="Obligatorio"
                                    />
                                    <button
                                        title="Nueva Manzana"
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        onClick={handleNewMz}
                                    >
                                        <Icon fontSize="medium" sx={{ color: "rgb(235, 234, 232)" }}>
                                            add_circle
                                        </Icon>
                                        Nueva Manzana
                                    </button>
                                </div> */}
                                {labelMzFieldset}

                                {labelFieldset}
                                {/* <fieldset className={"k-form-fieldset"}>
                                    <button
                                        title="Nueva Manzana"
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        onClick={handleNewMz}
                                    >
                                        <Icon fontSize="medium" sx={{ color: "rgb(235, 234, 232)" }}>
                                            add_circle
                                        </Icon>
                                        Nueva Manzana
                                    </button>
                                    <div className="mb-3">
                                        <Field
                                            name={"villa"}
                                            component={InputTelerik}
                                            label={"Villa"}
                                            maxLength={32}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Field
                                            name={"building"}
                                            component={InputTelerik}
                                            label={"Edificio"}
                                            maxLength={32} />
                                    </div>
                                    <div className="mb-3">
                                        <Field
                                            name={"floor"}
                                            //type={"identification"}
                                            component={InputTelerik}
                                            label={"Piso"}
                                            maxLength={32}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Field
                                            name={"department"}
                                            //type={"email"}
                                            component={InputTelerik}
                                            label={"Departamento"}
                                            maxLength={32}
                                        />
                                    </div>
                                </fieldset> */}
                                <div className="k-form-buttons">
                                    <button
                                        type={"submit"}
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                        disabled={!formRenderProps.allowSubmit}
                                    >
                                        <Icon fontSize="small" sx={{ color: "white" }}>
                                            save
                                        </Icon>
                                    </button>
                                </div>
                            </FormElement>
                        )}
                    />
                    {notification && (
                        <NotificationGroup
                            style={
                                position.bottomRight
                            }>
                            <Notification
                                key="error"
                                type={{
                                    style: "error",
                                    //margin: "center"
                                }}

                                closable={true}
                                onClose={() => viewNotification(false)}
                            >
                                {errorNotification}
                            </Notification>
                        </NotificationGroup>
                    )}
                </Dialog>
            )}

            {openAddNewMz && (
                <Dialog onClose={handleCancelNewMz} title='Ingrese Datos Para La Nueva Creación'>
                    <Form
                        onSubmit={newMzHandleSubmit}
                        initialValues={{
                            manzana: "",
                            description: "",
                            type,
                        }}
                        render={(formRenderProps) => (
                            <FormElement
                                style={{
                                    maxWidth: 650,
                                }}
                            >
                                <fieldset className={"k-form-fieldset"}>
                                    <div className="mb-3">
                                        <ComboBox
                                            id="cbxType"
                                            style={darkModeStyle}
                                            data={dataLocalityCatalog}
                                            textField="lca_description"
                                            onChange={handleNewMzType}
                                            placeholder="Seleccione Tipo"
                                            //name={"type"}
                                            //value={type}
                                            fillMode="outline"
                                            required
                                        />
                                    </div>
                                    {/* <div className="mb-3">
                                        <Field
                                            name={"manzana"}
                                            component={InputTelerik}
                                            label={"Manzana"}
                                            maxLength={8}
                                            required
                                            validationMessage={"Obligatorio"}
                                        />
                                    </div> */}
                                    {labelNewMzField}
                                    <div className="mb-3">
                                        <Field
                                            name={"description"}
                                            component={InputTelerik}
                                            label={"Ingrese motivo de creación"}
                                            maxLength={250}
                                        />
                                    </div>
                                </fieldset>
                                <div className="k-form-buttons">
                                    <button
                                        type={"submit"}
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        disabled={!formRenderProps.allowSubmit}
                                    > Guardar
                                        <Icon fontSize="medium">save</Icon>
                                    </button>
                                </div>
                            </FormElement>
                        )}
                    />
                </Dialog>
            )}

            {openFormNotification && (
                <Dialog onClose={handleCancelEdit} title='Modificación De Notificaciones'>
                    <Form
                        //onSubmit={LocalityHandleSubmit}
                        initialValues={{
                            localityNotification: localityNotification
                        }}
                        render={(formRenderProps) => (
                            <FormElement
                                style={{
                                    maxWidth: 650,
                                }}
                            >
                                <fieldset className={"k-form-fieldset"}>
                                    <div className="mb-3">
                                        <h6>Alerta Notificación</h6>
                                        <ComboBox
                                            //label={"Seleccione Notificacion"}
                                            id="cbxNotificacion"
                                            style={darkModeStyle}
                                            data={localityNotificationData}
                                            //value={value}
                                            textField="nti_type"
                                            onChange={handleLocalityNotificationChange}
                                            placeholder="Seleccione Notificación"
                                            value={localityNotificationData[localityNotification - 1]}
                                            fillMode="outline"
                                        />
                                    </div>
                                </fieldset>
                                <div className="k-form-buttons">
                                    <button
                                        //type={"submit"}
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                        onClick={LocalityNotificationHandleSubmit}
                                    >
                                        <Icon fontSize="small" sx={{ color: "white" }}>
                                            save
                                        </Icon>
                                    </button>
                                </div>
                            </FormElement>
                        )}
                    />
                    {notification && (
                        <NotificationGroup
                            style={
                                position.bottomRight
                            }>
                            <Notification
                                key="error"
                                type={{
                                    style: "error",
                                    //margin: "center"
                                }}

                                closable={true}
                                onClose={() => viewNotification(false)}
                            >
                                {errorNotification}
                            </Notification>
                        </NotificationGroup>
                    )}
                </Dialog>
            )}

            {actionResidentWindow && (
                <Dialog title={"Modificación de Localidad"}
                    onClose={handleCancelEdit}
                    style={{
                        color: "black",
                        height: "auto",
                        width: "auto",
                        paddingTop: "8%",
                        paddingLeft: "10%",
                        paddingRight: "2%"
                    }}
                >
                    <Grid container spacing={3} p={2}>
                        <Grid item xs={12} md={3} lg={3}>

                            <div style={{
                                boxShadow: "0px 10px 20px #00000029",
                                borderRadius: "20px",
                                padding: "8px"
                            }} >
                                <Form
                                    onSubmit={LocalityHandleSubmit}
                                    initialValues={{
                                        manzana: manzana,
                                        villa: villa,
                                        building: building,
                                        floor: floor,
                                        department: department,
                                        //localityNotification: localityNotification
                                    }}
                                    render={(formRenderProps) => (
                                        <FormElement
                                            style={{
                                                maxWidth: 650,
                                                //background: "blue",
                                                borderBlockColor: "blue"
                                            }}
                                        >
                                            <fieldset className={"k-form-fieldset"}>
                                                <h4>Localidad</h4>
                                                {labelField}
                                            </fieldset>
                                            {/* <fieldset className={"k-form-fieldset"}>
                                                <h4>Localidad</h4>
                                                <div className="mb-3">
                                                    <Field
                                                        name={"manzana"}
                                                        component={InputTelerik}
                                                        label={"Manzana"}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Field
                                                        name={"villa"}
                                                        component={InputTelerik}
                                                        label={"Villa"}
                                                        maxLength={32}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Field
                                                        name={"building"}
                                                        component={InputTelerik}
                                                        label={"Edificio"}
                                                        maxLength={32}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Field
                                                        name={"floor"}
                                                        //type={"identification"}
                                                        component={InputTelerik}
                                                        label={"Piso"}
                                                        maxLength={32}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Field
                                                        name={"department"}
                                                        //type={"email"}
                                                        component={InputTelerik}
                                                        label={"Departamento"}
                                                        maxLength={32}
                                                    />
                                                </div>
                                            </fieldset> */}
                                            <div className="k-form-buttons">
                                                <button
                                                    type={"submit"}
                                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                                    disabled={!formRenderProps.allowSubmit}
                                                >
                                                    Actualizar Datos
                                                </button>
                                            </div>
                                        </FormElement>
                                    )}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <TabStrip selected={selectedAction} onSelect={handleSelectAction}>
                                {/* <TabStripTab title="Falso">
                                </TabStripTab> */}
                                <TabStripTab title="Residentes">
                                    <LocalityResidents value_localityID={idLocality} value_localityUuid={uuidLocality}
                                        value_company={company.company_uuid} value_enterprise={enterprise.enterprise_uuid} />
                                </TabStripTab>
                                <TabStripTab title="Teléfonos">
                                    <LocalityContactPhones value_localityID={idLocality} value_localityUuid={uuidLocality} />
                                </TabStripTab>
                                <TabStripTab title="Lista Negra">
                                    <BlackList value_localityID={idLocality} value_company={company.company_uuid} value_enterprise={enterprise.enterprise_uuid} company_name={company.company_name}/>
                                </TabStripTab>
                                <TabStripTab title="PreAutorizados">
                                    <PreAuthorized value_localityID={idLocality} value_company={company.company_uuid} value_enterprise={enterprise.enterprise_uuid} />
                                </TabStripTab>
                                <TabStripTab title="Vehículos">
                                    <Vehicles value_localityID={idLocality} value_company={company.company_uuid} value_enterprise={enterprise.enterprise_uuid} />
                                </TabStripTab>
                            </TabStrip>
                        </Grid>

                    </Grid>
                    <Button
                        className={"k-button k-button-md k-rounded-md k-button-solid-primary k-grid-save-command"}
                        style={
                            position.bottomLeft
                        }
                        onClick={handleCancelEdit}>

                        <Icon fontSize="small"
                            style={{
                                paddingTop: "1px"
                            }} >
                            cancel
                        </Icon>
                        {/* &nbsp;
                        CERRAR */}
                    </Button>

                    {notification && (
                        <NotificationGroup
                            style={
                                position.bottomRight
                            }>
                            <Notification
                                key="error"
                                type={{
                                    style: "error",
                                    //margin: "center"
                                }}

                                closable={true}
                                onClose={() => viewNotification(false)}
                            >
                                {errorNotification}
                            </Notification>
                        </NotificationGroup>
                    )}

                    {notificationSuccess && (
                        <NotificationGroup
                            style={
                                position.bottomRight
                            }>
                            <Notification
                                key="error"
                                type={{
                                    style: "success",
                                    //margin: "center"
                                }}

                                closable={true}
                                onClose={() => viewNotificationSuccess(false)}
                            >
                                {content}
                            </Notification>
                        </NotificationGroup>
                    )}

                </Dialog>

            )}

            {notificationSuccess && (
                <NotificationGroup
                    style={
                        position.bottomRight
                    }>
                    <Notification
                        key="error"
                        type={{
                            style: "success",
                            //margin: "center"
                        }}

                        closable={true}
                        onClose={() => viewNotificationSuccess(false)}
                    >
                        {content}
                    </Notification>
                </NotificationGroup>
            )}

            {notification && (
                <NotificationGroup
                    style={
                        position.bottomRight
                    }>
                    <Notification
                        key="error"
                        type={{
                            style: "error",
                            //margin: "center"
                        }}

                        closable={true}
                        onClose={() => viewNotification(false)}
                    >
                        {errorNotification}
                    </Notification>
                </NotificationGroup>
            )}
        </>
    )
}
