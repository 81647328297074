import * as React from "react";

import {
  Grid as GridTelerik,
  GridToolbar,
  GridColumn,
} from "@progress/kendo-react-grid";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import {
  FormInput,
  FormMaskedTextBox,
} from "../../shared/form-components";
import {
  descriptionContactValidator,
  contactPhoneValidator
} from "../../shared/form-validators";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";

import { Icon } from "@mui/material";

import { EditFormContactPhone } from "./EditFormContactPhone";
import { useResidentStore } from "hooks";

export const LocalityContactPhones = ({ value_localityID, value_localityUuid }) => {
  const { statusLoad,
    apiLocalityContactData,
    contactSave, errorMessageResident,
    contactDelete, updateContactPhone,
    clearLocalityContactPhoneStatus,
    apiLocalityContactSave, apiLocalityContactEdit
  } = useResidentStore();

  const user_uuid = localStorage.getItem("user");

  const [openFormContact, setOpenFormContact] = React.useState(false);
  const [editItem, setEditItem] = React.useState();

  const [dataContactLocality, setDataContactLocality] = React.useState([]);

  const handleCancelEdit = () => {
    setOpenFormContact(false);
  };

  const cellWithPriority = (props) => {
    const valuePriority = props.dataItem.cph_priority === "1";
    const icon = valuePriority ? (
      <Icon fontSize="large">phone</Icon>
    ) : (
      <Button fillMode="flat" onClick={() => onChangePriority(props.dataItem)}>
        <Icon fontSize="large">arrow_circle_up</Icon>
      </Button>
    );
    return (
      <td
        style={{

          fontSize: "2rem"
        }}
      >
        <label>{props.dataItem.cph_priority}</label> {icon}
      </td>
    );
  };

  const onChangePriority = (item) => {
    //console.log(item.cph_description)
    const request_priority1 = {
      contact_phone_uuid: item.cph_uuid,
      contact_phone_locality_id: parseInt(item.cph_locality_id),
      contact_phone_number: item.cph_number,
      contact_phone_priority: 1,
      contact_phone_description: item.cph_description,
      contact_phone_last_modifier_id: user_uuid
    }
    updateContactPhone(request_priority1);
    const request_priority2 = {
      contact_phone_uuid: dataContactLocality[0].cph_uuid,
      contact_phone_locality_id: parseInt(item.cph_locality_id),
      contact_phone_number: dataContactLocality[0].cph_number,
      contact_phone_priority: 2,
      contact_phone_description: dataContactLocality[0].cph_description,
      contact_phone_last_modifier_id: user_uuid
    }
    updateContactPhone(request_priority2);
  };

  const CommandCell = (props) => {
    const { dataItem } = props;
    return <td className="k-command-cell">
      <button title="Editar Número" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command" onClick={() => enterEdit(dataItem)}>
        <Icon fontSize="small" sx={{ color: "white" }}>
          edit
        </Icon>
      </button>
      <button title="Eliminar Número" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command" onClick={() => remove(dataItem)}>
        <Icon fontSize="small" sx={{ color: "white" }}>
          delete
        </Icon>
      </button>
    </td>;
  };

  const enterEdit = (item) => {
    setOpenFormContact(true);
    setEditItem(item);
  };

  const [removeItem, setRemoveItem] = React.useState(undefined);
  const remove = (dataItem) => {
    setRemoveItem(dataItem);
    if ((dataItem.cph_priority === "1") && (dataContactLocality.length > 1)) {
      //console.log('No se pude eliminar');
      setContentError("No se puede eliminar el teléfono principal, cámbielo a secundario para borrarlo");
      viewNotificationError(true);
      setTimeout(() => {
        viewNotificationError(false);
      }, 6000);
    }
    const request_remove = {
      contact_phone_uuid: dataItem.cph_uuid,
      contact_phone_deletion_id: user_uuid
    }
    contactDelete(request_remove);
  };

  const [notificationSuccess, setNotificationSuccess] = React.useState(false);
  const viewNotificationSuccess = (state) => {
    setNotificationSuccess(state)
  };
  const [contentSuccess, setContentSuccess] = React.useState("<span></span>");

  const [notificationError, setNotificationError] = React.useState(false);
  const viewNotificationError = (state) => {
    setNotificationError(state)
  };
  const [contentError, setContentError] = React.useState("<span></span>");


  const position = {
    bottomRight: {
      bottom: 0,
      right: 0,
      alignItems: "flex-end",
    },
    topRight: {
      top: 5,
      left: "95%",
      alignItems: "flex-end",
    },
  }

  const handleSubmit = (dataItem) => {
    const phone = dataItem.phoneNumber.split("_")
    const descriptionValue = (dataItem.fullName).toUpperCase();
    const request = {
      "contact_phone_locality_id": value_localityID,
      "contact_phone_number": phone[0],
      "contact_phone_priority": contactPhoneData.length === 0 ? 1 : 2,
      "contact_phone_description": descriptionValue,
      "contact_phone_file": false,
      "contact_phone_creator_id": user_uuid
    }
    contactSave(request);
  };

  const handleSubmitEdit = (event) => {
    const phone = event.cph_number.split("_")
    const descriptionValue = (event.cph_description).toUpperCase();
    const request_edit = {
      contact_phone_uuid: event.cph_uuid,
      contact_phone_locality_id: parseInt(event.cph_locality_id),
      contact_phone_number: phone[0],
      contact_phone_priority: parseInt(event.cph_priority),
      contact_phone_description: descriptionValue,
      contact_phone_last_modifier_id: user_uuid
    }
    //console.log(request_edit)
    updateContactPhone(request_edit);
  };

  const [addContactPhoneDialog, setAddContactPhoneDialog] = React.useState(false);
  const handleCancelNewPhone = () => {
    setAddContactPhoneDialog(false);
  };

  const [contactPhoneData, setContactPhoneData] = React.useState([]);

  const addContactPhone = () => {
    setAddContactPhoneDialog(true);
    viewNotificationSuccess(false);
    viewNotificationError(false);
  };

  React.useEffect(() => {
    if (statusLoad === '3') {
      setContentSuccess('Registro Guardado Exitosamente');
      viewNotificationSuccess(true);
      setTimeout(() => {
        viewNotificationSuccess(false);
      }, 4000);
      setAddContactPhoneDialog(false);
    }

    if (statusLoad === '4') {
      const newDataState = [...dataContactLocality];
      let index = newDataState.findIndex((record) => record.cph_id === removeItem.cph_id);
      newDataState.splice(index, 1);
      setDataContactLocality(newDataState);
    }

    if (statusLoad === '2') {
      setContentSuccess('Registro Actualizado Exitosamente');
      viewNotificationSuccess(true);
      setTimeout(() => {
        viewNotificationSuccess(false);
      }, 4000);
      setAddContactPhoneDialog(false);
      setOpenFormContact(false);
    }
    clearLocalityContactPhoneStatus();
  }, [statusLoad])

  React.useEffect(() => {
    if (apiLocalityContactEdit?.data !== undefined) {
      setContactPhoneData(apiLocalityContactEdit);
      setDataContactLocality(apiLocalityContactEdit.data.map((dataItem) => ({
        ...dataItem
      })));
    } else {
      setContactPhoneData([]);
      setDataContactLocality([]);
    }
  }, [apiLocalityContactEdit])


  React.useEffect(() => {
    if (apiLocalityContactSave?.data !== undefined) {
      setContactPhoneData(apiLocalityContactSave);
      setDataContactLocality(apiLocalityContactSave.data.map((dataItem) => ({
        ...dataItem
      })));
    } else {
      setContactPhoneData([]);
      setDataContactLocality([]);
    }
  }, [apiLocalityContactSave])

  React.useEffect(() => {
    if (apiLocalityContactData?.data !== undefined) {
      //console.log(apiLocalityContactData?.data);
      setContactPhoneData(apiLocalityContactData.data);
      setDataContactLocality(apiLocalityContactData.data.map((dataItem) => ({
        ...dataItem
      })));
    } else {
      setContactPhoneData([]);
      setDataContactLocality([]);
    }
  }, [apiLocalityContactData]);

  React.useEffect(() => {
    if (errorMessageResident !== undefined) {
      console.log(errorMessageResident);
      setContactPhoneData([]);
      setDataContactLocality([]);
      setContentError('No existe teléfonos en la localidad');
      viewNotificationError(true);
      setTimeout(() => {
        viewNotificationError(false);
      }, 4000);
    }
  }, [errorMessageResident])

  return (
    <>
      <GridTelerik
        style={{
          height: "420px",
        }}
        data={dataContactLocality}
      >
        <GridToolbar>
          <div>
            <button
              title="Add new"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={addContactPhone}
            >
              <Icon fontSize="medium" sx={{ color: "rgb(235, 234, 232)" }}>
                add_circle
              </Icon>
              Agregar Número
            </button>
          </div>
        </GridToolbar>

        <GridColumn field="cph_number" title="TELÉFONO REGISTRADO" width="220px" />

        <GridColumn field="cph_priority" title="PRIORIDAD" width="150px" cell={cellWithPriority} />

        <GridColumn field="cph_description" title="NOMBRES Y APELLIDOS" />

        <GridColumn title="OPCIONES" cell={CommandCell} width="120px" />
      </GridTelerik>
      {openFormContact && (
        <EditFormContactPhone
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmitEdit}
          item={editItem}
        />
      )}

      {addContactPhoneDialog && (
        <Dialog
          onClose={handleCancelNewPhone}
          title={"Nuevo Teléfono Contacto"}
          maxWidth={"auto"}
        >
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
              <FormElement
                style={{
                  width: 500,
                }}
              >
                <fieldset className={"k-form-fieldset"}>
                  <Field
                    id={"fullName"}
                    name={"fullName"}
                    label={"NOMBRES Y APELLIDOS *"}
                    component={FormInput}
                    validator={descriptionContactValidator}
                    maxLength={100}
                  />
                  <Field
                    id={"phoneNumber"}
                    name={"phoneNumber"}
                    label={"TELÉFONO"}

                    hint={"Sugerencia: Número de teléfono activo (0987654321 / 042123456 / 2123456 / 10001XXX)."}
                    component={FormMaskedTextBox}
                    maxLength={10}
                    validator={contactPhoneValidator}
                    mask={"0000000000" || "000000000" || "0000000"}
                  />
                  <div className="k-form-buttons" style={{ marginLeft: "80%" }}>
                    <Button
                      themeColor={"primary"}
                      type={"submit"}
                      disabled={!formRenderProps.allowSubmit}
                    >
                      <Icon fontSize="small" sx={{ color: "white" }} >
                        save
                      </Icon>
                    </Button>
                    <Button onClick={formRenderProps.onFormReset}>
                      <Icon fontSize="small" sx={{ color: "white" }} >
                        backspace_rounded
                      </Icon>
                    </Button>
                  </div>
                </fieldset>
              </FormElement>
            )}
          />
          {notificationError && (
            <NotificationGroup
              style={
                position.bottomRight
              }>
              <Notification
                key="error"
                type={{
                  style: "error",
                }}
                closable={true}
                onClose={() => viewNotificationError(false)}
              >
                {contentError}
              </Notification>
            </NotificationGroup>
          )}
        </Dialog>
      )}

      {notificationSuccess && (
        <NotificationGroup
          style={
            position.bottomRight
          }>
          <Notification
            key="error"
            type={{
              style: "success",
            }}
            closable={true}
            onClose={() => viewNotificationSuccess(false)}
          >
            {contentSuccess}
          </Notification>
        </NotificationGroup>
      )}

    </>
  );
};
